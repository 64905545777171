<template>
    <b-container fluid>
         <card>
           <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t('elearning_tpm.training_attendence_sheet') }} {{ $t('globalTrans.search') }}</h4>
            </template>
            <template v-slot:body>
              <b-row>
                <b-col lg="12" sm="12">
                  <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                    <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                      <b-row>
                        <b-col lg="6" sm="6">
                          <ValidationProvider name="Circular Memo No" vid="circular_memo_no" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="circular_memo_no"
                                slot-scope="{ valid, errors }"
                            >
                              <template v-slot:label>
                                {{$t('elearning_iabm.circular_memo_no')}} <span class="text-danger">*</span>
                              </template>
                              <b-form-select
                                    plain
                                    v-model="search.circular_memo_no"
                                    :options="circularList"
                                    id="circular_memo_no"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{ circularLoading ? 'Loading....' : $t('globalTrans.select') }}</b-form-select-option>
                                  </template>
                                </b-form-select>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                        </b-col>
                      </b-row>
                      <b-overlay :show="load">
                        <b-row>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Fiscal Year"  vid="fiscal_year_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="fiscal_year_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.fiscal_year')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.fiscal_year_id"
                                    :options="fiscalYearList"
                                    id="fiscal_year_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12">
                            <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="org_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.organization')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.org_id"
                                    :options="orgList"
                                    id="org_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Office Type" vid="office_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.office_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.office_type_id"
                                    :options="officeTypeList"
                                    id="office_type_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Office" vid="office_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="office_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('globalTrans.office')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                    plain
                                    v-model="search.office_id"
                                    :options="officeList"
                                    id="office_id"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    disabled
                                >
                                  <template v-slot:first>
                                    <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_type_id"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.training_type_id"
                                  :options="trainingTypeList"
                                  id="training_type_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                  <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Category" vid="training_category_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_category_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_category')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.training_category_id"
                                  :options="trainingCategoryList"
                                  id="training_category_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                              <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="training_title_id"
                                slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-select
                                  plain
                                  v-model="search.training_title_id"
                                  :options="trainingTitleList"
                                  id="training_title_id"
                                  :state="errors[0] ? false : (valid ? true : null)"
                                  disabled
                                  >
                                  <template v-slot:first>
                                      <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                  </template>
                                  </b-form-select>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training Start Date" vid="training_start_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_start_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_iabm.training_start_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    v-model="search.training_start_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                          <b-col lg="6" sm="6">
                            <ValidationProvider name="Training End Date" vid="training_end_date" rules="required">
                              <b-form-group
                                  class="row"
                                  label-cols-sm="4"
                                  label-for="training_end_date"
                                  slot-scope="{ valid, errors }"
                              >
                                <template v-slot:label>
                                  {{ $t('elearning_iabm.training_end_date') }} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    class="fromDate"
                                    v-model="search.training_end_date"
                                    :placeholder="$t('globalTrans.select_date')"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                  {{ errors[0] }}
                                </div>
                              </b-form-group>
                            </ValidationProvider>
                          </b-col>
                        </b-row>
                      </b-overlay>
                    </b-form>
                  </ValidationObserver>
                </b-col>
            </b-row>
            </template>
        </card>
        <b-overlay :show="loading">
        <b-row>
          <b-col md="12" v-if="showData">
            <b-overlay>
               <card v-if='datas.length'>
                <template v-slot:body>
                  <b-row>
                    <b-col md="12">
                       <card>
                        <template v-slot:headerTitle>
                          <h4 class="card-title">{{ $t('elearning_tpm.training_attendence_sheet') }}</h4>
                        </template>
                        <template v-slot:headerAction>
                          <b-button class="btn_add_new" @click="pdfExport">
                            <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
                          </b-button>
                        </template>
                        <template v-slot:body>
                          <b-overlay>
                            <b-row mt-5>
                              <b-col md="12" class="table-responsive">
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col v-for="(info, index) in dateList" :key="index">
                                          <b-form-checkbox
                                            :id="'checkbox-' + info.text"
                                            v-model="info.checked"
                                            :name="'checkbox-' + info.text"
                                            value=2
                                            unchecked-value=1
                                            @change="checkCount(info)"
                                          >
                                          {{info.text | dateFormat}}
                                          </b-form-checkbox>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div style="border: 2px solid;margin:10px;padding:10px">
                                    <b-row>
                                        <b-col>
                                            <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                                                <template v-slot:projectNameSlot>
                                                {{ }}
                                                </template>
                                                {{ $t('elearning_tpm.training_attendence_sheet') }}
                                            </list-report-head>
                                        </b-col>
                                    </b-row>
                                  <b-row>
                                    <b-col>
                                      <div class="text-center">
                                        <b-row>
                                          <b-col>
                                            <table style="width:100%;color:black;">
                                              <tr v-if="search.circular_memo_no">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.circular_memo_no') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.circular_memo_no }}</td>
                                              </tr>
                                              <tr v-if="search.org && ($store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1 || $store.state.Auth.authUser.org_id === 12)">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.organization') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.org_bn : search.org }}</td>
                                              </tr>
                                              <tr v-if="search.ofc_name">
                                                <td align="right" style="width:45%">{{ $t('globalTrans.office') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.ofc_name_bn : search.ofc_name }}</td>
                                              </tr>
                                              <tr v-if="search.training_title">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_title') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_title_bn : search.training_title }}</td>
                                              </tr>
                                               <tr v-if="search.training_end_date">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.training_end_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.training_end_date | dateFormat }}</td>
                                              </tr>
                                            </table>
                                          </b-col>
                                          <b-col>
                                            <table style="width:100%;color:black;">
                                              <tr v-if="search.fiscal_year">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.fiscal_year') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.fiscal_year_bn : search.fiscal_year }}</td>
                                              </tr>
                                              <tr v-if="search.ofc_type_name">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.office_type') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.ofc_type_name_bn : search.ofc_type_name }}</td>
                                              </tr>
                                              <tr v-if="search.training_category">
                                                <td align="right" style="width:45%">{{ $t('elearning_config.training_category') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ ($i18n.locale==='bn') ? search.training_category_bn : search.training_category }}</td>
                                              </tr>
                                              <tr v-if="search.training_start_date">
                                                <td align="right" style="width:45%">{{ $t('elearning_iabm.training_start_date') }}</td>
                                                <td align="center" style="width:5%">:</td>
                                                <td align="left" style="width:50%">{{ search.training_start_date | dateFormat }}</td>
                                              </tr>
                                            </table>
                                          </b-col>
                                        </b-row>
                                      </div>
                                    </b-col>
                                  </b-row>
                                  <b-row>
                                    <b-col>
                                      <b-table-simple class="tg mt-3" bordered hover small caption-top responsive v-if="dateList.filter(item1 => item1.checked === '2').length>0">
                                        <b-thead>
                                          <b-tr>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('globalTrans.sl_no') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.trainee_name') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.mobile_no') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.father_name') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_accommodation.address') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.age') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('admission_form.monthly_income') }}</b-th>
                                            <b-th style="width:10%" class="text-center" rowspan="2">{{ $t('elearning_tpm.edu_quali') }}</b-th>
                                            <b-th style="width:10%" class="text-center" colspan="3">{{ $t('elearning_tpm.sig_date') }}</b-th>
                                          </b-tr>
                                          <b-tr>
                                            <slot v-for="(field) in dateList.filter(item1 => item1.checked === '2')">
                                              <b-th  style="width:10%" class="text-center">{{ field.text | dateFormat }}</b-th>
                                            </slot>
                                          </b-tr>
                                        </b-thead>
                                        <b-tbody  v-for="(info, index) in datas" :key="index">
                                          <b-td class="text-center">{{ $n(index + 1) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_bn : info.name }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale == 'bn' ? '০': '0') + $n(info.mobile, { useGrouping: false }) }}</b-td>
                                          <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.name_of_father_husband_bn : info.name_of_father_husband }}</b-td>
                                          <b-td class="text-center">{{ info.other_address }}</b-td>
                                          <b-td class="text-center">{{ $n(info.age) }}</b-td>
                                          <b-td class="text-center">{{ info.monthly_income }}</b-td>
                                          <b-td class="text-center">{{ info.name_of_highest_degree }}</b-td>
                                          <slot v-for="(field, index1) in dateList.filter(item1 => item1.checked === '2')">
                                            <b-td style="width:10%" class="text-center" :id="'text' + field" :key="index1"></b-td>
                                          </slot>
                                        </b-tbody>
                                      </b-table-simple>
                                    </b-col>
                                  </b-row>
                                </div>
                              </b-col>
                            </b-row>
                          </b-overlay>
                        </template>
                      </card>
                    </b-col>
                  </b-row>
                </template>
              </card>
              <div class="panel-body text-center mt-3" v-else>
                <h6 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
              </div>
            </b-overlay>
          </b-col>
          <!-- <pre>{{dateList}}</pre> -->
        </b-row>
        </b-overlay>
    </b-container>
</template>
<script>

import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { attendenceSheetList, circularPublicationList, circularPublication } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/list'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import exportPdf from './export_pdf_list'
import flatpickr from 'flatpickr'
export default {
    mixins: [ModalBaseMasterList],
     components: {
      ListReportHead
    },
    created () {
      this.getCircularList()
    },
    data () {
        return {
          search: {
            circular_memo_no: 0,
            org_id: 0,
            training_type_id: 0,
            training_category_id: 0,
            training_title_id: 0,
            venue_id: 0,
            fiscal_year_id: 0,
            registration_for: 2,
            training_start_date: '',
            office_type_id: 0,
            office_id: 0
          },
          load: false,
          trainingCategoryList: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          datas: [],
          showData: true,
          loading: false,
          trainingTitleList: [],
          circularList: [],
          officeList: [],
          dateList: [],
          officeTypeList: [],
          circularLoading: false
        }
    },
    computed: {
      trainingTypeList: function () {
        return this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
      },
      fiscalYearList: function () {
        return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
      },
      orgList: function () {
        return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
      }
    },
    mounted () {
    flatpickr('.fromDate', {})
  },
    watch: {
      'search.circular_memo_no': function (newValue) {
        this.getCircularMemoNo(newValue)
      },
      'search.org_id': function (newValue) {
        this.officeTypeList = this.getOfficeTypeList(newValue)
      },
      'search.office_type_id': function (newValue) {
        this.officeList = this.getOfficeList(newValue)
      },
      'search.training_type_id': function (newValue) {
        this.trainingCategoryList = this.getCategoryList(newValue)
      },
      'search.training_category_id': function (newValue) {
        this.trainingTitleList = this.getTrainingTitleList(newValue)
      }
    },
    methods: {
      ageCalculation (dateofBirth) {
        const today = new Date()
        const birthDate = new Date(dateofBirth)
        let age = today.getFullYear() - birthDate.getFullYear()
        const m = today.getMonth() - birthDate.getMonth()
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--
        }
        return age
      },
      getOfficeTypeList (orgId) {
        const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
        if (orgId) {
          return OfficeTypeList.filter(item => item.org_id === orgId)
        }
        return OfficeTypeList
      },
      getOfficeList (officeTypeId) {
        const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
        if (officeTypeId) {
          return officeList.filter(item => item.office_type_id === officeTypeId)
        }
        return officeList
      },
      async getCircularList () {
        this.circularLoading = true
        const serchData = {
          org_id: this.$store.state.dataFilters.orgId
        }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublicationList + '/' + 'iab_circular_publications/2', serchData)
        if (!result.success) {
        } else {
          const listObject = result.data
          const tmpList = listObject.map((obj, index) => {
            if (this.$i18n.locale === 'bn') {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            } else {
              return { value: obj.circular_memo_no, text: obj.circular_memo_no }
            }
          })
          this.circularList = tmpList
        }
        // if (this.$route.query.id) {
        //   this.getFormData()
        // }
        this.circularLoading = false
      },
      checkCount (item) {
        const total = this.dateList.filter(item1 => item1.checked === '2')
        if (total.length > 3) {
          this.$toast.error({
            title: 'Error',
            message: this.$i18n.locale === 'bn' ? 'সর্বোচ্চ তিনটি তারিখ যোগ করা যাবে' : 'Maximum three dates can be added!'
          })
          item.checked = false
        }
      },
      async getCircularMemoNo (getCircularMemoNo) {
        this.load = true
          const params = {
            circular_memo_no: getCircularMemoNo,
            table: 'iab_circular_publications'
          }
        const result = await RestApi.getData(trainingElearningServiceBaseUrl, circularPublication, params)
        if (!result.success) {
        this.search.fiscal_year_id = 0
          this.search.org_id = 0
          this.search.office_id = 0
          this.search.office_type_id = 0
          this.search.training_title_id = 0
          this.search.training_type_id = 0
          this.search.training_category_id = 0
          this.search.training_start_date = ''
          this.search.training_end_date = ''
          this.datas = []
        } else {
          this.lcErrorMsg = ''
          const trData = result.data
          this.search.fiscal_year_id = trData.fiscal_year_id
          this.search.org_id = trData.org_id
          this.search.office_id = trData.office_id
          this.search.office_type_id = trData.office_type_id
          this.search.training_title_id = trData.training_title_id
          this.search.training_type_id = trData.training_type_id
          this.search.training_category_id = trData.training_category_id
          this.search.training_start_date = trData.training_start_date
          this.search.training_end_date = trData.training_end_date
          const listData = result.aryRange.map(item => {
            const updateData = {}
            updateData.checked = false
            updateData.text = item
            return Object.assign({}, updateData)
          })
          this.dateList = listData
          this.searchData()
        }
        this.load = false
      },
      getCategoryList (typeId) {
        const trainingCategoryList = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.filter(item => item.status === 1)
        if (typeId) {
          return trainingCategoryList.filter(item => item.training_type_id === typeId)
        }
        return trainingCategoryList
      },
      getTrainingTitleList (categoryId) {
        const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
        if (categoryId) {
          return trainingTitleList.filter(item => item.training_category_id === categoryId)
        }
        return trainingTitleList
      },
      async searchData () {
        this.loadData()
      },
      loadData () {
        this.loading = true
        this.getCustomDataSearch()
        RestApi.getData(trainingElearningServiceBaseUrl, attendenceSheetList, this.search).then(response => {
          if (response.success) {
            this.datas = response.data
          }
          this.loading = false
        })
      },
      getCustomDataSearch () {
        const orgObj = this.$store.state.commonObj.organizationProfileList.find(organizationProfileList => organizationProfileList.value === parseInt(this.search.org_id))
        if (typeof orgObj !== 'undefined') {
          this.search.org = orgObj.text_en
          this.search.org_bn = orgObj.text_bn
        } else {
          this.search.org = ''
          this.search.org_bn = ''
        }
        const fiscalYearObj = this.$store.state.commonObj.fiscalYearList.find(fiscalYearList => fiscalYearList.value === parseInt(this.search.fiscal_year_id))
        if (typeof fiscalYearObj !== 'undefined') {
          this.search.fiscal_year = fiscalYearObj.text_en
          this.search.fiscal_year_bn = fiscalYearObj.text_bn
        //   this.search.fiscal_year_bn = fiscalYearObj.text_bn
        } else {
          this.search.fiscal_year = ''
          this.search.fiscal_year_bn = ''
        }
        const trainingTitleObj = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_title_id))
        if (typeof trainingTitleObj !== 'undefined') {
          this.search.training_title = trainingTitleObj.text_en
          this.search.training_title_bn = trainingTitleObj.text_bn
        } else {
          this.search.training_title = ''
          this.search.training_title_bn = ''
        }
        const trainingTypeObj = this.$store.state.TrainingElearning.commonObj.trainingTypeList.find(doc => doc.value === parseInt(this.search.training_type_id))
        if (typeof trainingTypeObj !== 'undefined') {
          this.search.training_type = trainingTypeObj.text_en
          this.search.training_type_bn = trainingTypeObj.text_bn
        } else {
          this.search.training_type = ''
          this.search.training_type_bn = ''
        }
        const trainingCategoryObj = this.$store.state.TrainingElearning.commonObj.trainingCategoryList.find(trainingCategoryList => trainingCategoryList.value === parseInt(this.search.training_category_id))
        if (typeof trainingCategoryObj !== 'undefined') {
          this.search.training_category = trainingCategoryObj.text_en
          this.search.training_category_bn = trainingCategoryObj.text_bn
        } else {
          this.search.training_category = ''
          this.search.training_category_bn = ''
        }
        const trainerObj = this.$store.state.TrainingElearning.commonObj.personalInfoList.find(personalInfoList => personalInfoList.value === parseInt(this.search.trainer_id))
        if (typeof trainerObj !== 'undefined') {
          this.search.trainer_name = trainerObj.text_en
          this.search.trainer_name_bn = trainerObj.text_bn
        } else {
          this.search.trainer_name = ''
          this.search.trainer_name_bn = ''
        }
        const ofcTypeObj = this.$store.state.commonObj.officeTypeList.find(ofcTypeList => ofcTypeList.value === parseInt(this.search.office_type_id))
        if (typeof ofcTypeObj !== 'undefined') {
          this.search.ofc_type_name = ofcTypeObj.text_en
          this.search.ofc_type_name_bn = ofcTypeObj.text_bn
        } else {
          this.search.ofc_type_name = ''
          this.search.ofc_type_name_bn = ''
        }
        const ofcObj = this.$store.state.commonObj.officeList.find(ofcList => ofcList.value === parseInt(this.search.office_id))
        if (typeof ofcObj !== 'undefined') {
          this.search.ofc_name = ofcObj.text_en
          this.search.ofc_name_bn = ofcObj.text_bn
        } else {
          this.search.ofc_name = ''
          this.search.ofc_name_bn = ''
        }
      },
      pdfExport () {
        const reportTitle = this.$t('elearning_tpm.training_attendence_sheet')
        exportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this, this.datas, this.search, this.dateList.filter(item1 => item1.checked === '2'))
      }
    }
}
</script>
